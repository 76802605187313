<div class="container-max">
    <app-breadcrumbs
        [breacrumbList]="breadcrumbsList"
        [title]="breadcrumbsTitle"
        [showButtonBack]="true"
    ></app-breadcrumbs>
    <div class="wrapper-view-edit-list">
        <div class="inner-content-max">
            <div nz-row nzGutter="16">
                <div nz-col nzXXl="6" nzXl="6" nzLg="6" nzMd="6" nzXs="6" nzSm="6">
                    <div class="card-user" [ngClass]="{'border-cancel': orderDetail?.order?.status !== superStatus.active && orderDetail?.order?.status !== superStatus.in_process, 'border-complete': orderDetail?.order?.status === superStatus.active, 'border-pending-row': orderDetail?.order?.status === superStatus.in_process}">
                        <div class="inner-card-user">
                            <div class="dot c-amena" [ngClass]="{'c-cancel': orderDetail?.order?.status !== superStatus.active && orderDetail?.order?.status !== superStatus.in_process, 'c-complete': orderDetail?.order?.status === superStatus.active, 'c-pending-row': orderDetail?.order?.status === superStatus.in_process}"></div>
                            <div class="cursor-pointer" (click)="onShow()">
                                <div class="flex-content a-base">
                                    <p class="font s-16 c-black w-bold" style="margin-bottom: 0px;">
                                        <img *ngIf="orderDetail.order.contingency" style="margin-right: 3px; width: 17px; height: 17px;" src="assets/images/icono-teleoperador.png" nzTooltipTitle="Alta por soporte" nzTooltipPlacement="top" nz-tooltip/>
                                        {{ orderDetail?.customer?.name }}</p>
                                    <!-- <i nz-icon nzType="right"
                                        nzTheme="outline"
                                        class="font c-primary"
                                        [ngClass]="isCollapsedAdditionalInfo === true ? 'arrow-active' : 'arrow-no-active'">
                                    </i> -->
                                </div>
                                <div *ngIf="orderDetail?.customer?.documentType" class="font s-12 c-black w-bold">{{ orderDetail?.customer?.documentType + ':  ' + orderDetail?.customer?.documentNumber }}</div>
                                <span class="font c-primary" *ngIf="!isCollapsedAdditionalInfo && ((orderDetail?.customer?.nationality && orderDetail?.customer?.nationality !== '') || (orderDetail?.customer?.sex && orderDetail?.customer?.sex !== '') || (orderDetail?.customer?.birthDate && orderDetail?.customer?.birthDate !== ''))">Información adicional +</span>
                                <ng-container *ngIf="isCollapsedAdditionalInfo">
                                    <div class="font c-black s-12 w-bold" *ngIf="orderDetail?.customer?.nationality && orderDetail?.customer?.nationality !== ''">
                                        Nacionalidad: {{ orderDetail?.customer?.nationality }}
                                    </div>
                                    <div class="font c-black s-12 w-bold" *ngIf="orderDetail?.customer?.sex && orderDetail?.customer?.sex !== ''">
                                        Sexo: {{ orderDetail?.customer?.sex }}
                                    </div>
                                    <div class="font c-black s-12 w-bold" *ngIf="orderDetail?.customer?.birthDate && orderDetail?.customer?.birthDate !== ''">
                                        Fecha de nacimiento: {{ orderDetail?.customer?.birthDate }}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div nz-col nzXXl="18" nzXl="18" nzLg="18" nzMd="18" nzXs="18" nzSm="18">
                    <div class="card-info-user" style="border: 1px solid lightgrey">
                        <div nz-row nzJustify="space-around" nzAlign="bottom" style="width: 100%; display: contents;">
                            <!-- <div nz-col style="width: 100%;" *ngIf="orderDetail?.order?.contractNumber && orderDetail?.order?.contractNumber !== '' && orderDetail?.order?.showContract">
                                <div class="content-card item">
                                    <p class="font s-13 c-gray t-up a-center">Nº DE CONTRATO</p>
                                    <span class="font s-13 c-black t-up a-center w-bold">{{ orderDetail?.order?.contractNumber }}</span>
                                </div>
                            </div> -->
                            <div nz-col style="width: 100%;" *ngIf="orderDetail?.order?.orderType && orderDetail?.order?.orderType !== '' && orderDetail?.pdv?.showPdv !== false">
                                <div class="content-card item">
                                    <p class="font s-13 c-gray t-up a-center">TIPO DE PEDIDO</p>
                                    <div class="flex-content cursor-pointer" nz-tooltip
                                        [nzTooltipTitle]="orderDetail?.order?.contractNumber && orderDetail?.order?.contractNumber !== '' && !isMobile ? 'Nº de contrato: ' + orderDetail?.order?.contractNumber : ''">
                                        <span class="font s-13 c-black t-up a-center w-bold">{{ orderDetail?.order?.orderType }}</span>
                                        <span class="font s-13 c-black t-up a-center margins m-l-5" 
                                            *ngIf="orderDetail?.order?.contractNumber && orderDetail?.order?.contractNumber !== '' && isMobile">
                                            {{ '(' + orderDetail?.order?.contractNumber + ')' }}
                                        </span>
                                        <i nz-icon nzType="info-circle" class="margins m-l-5" nzTheme="outline" *ngIf="orderDetail?.order?.contractNumber && orderDetail?.order?.contractNumber !== '' && !isMobile"></i>
                                    </div>
                                </div>
                            </div>
                            <div nz-col style="width: 100%;" *ngIf="orderDetail?.order?.tramitedDate && orderDetail?.order?.tramitedDate !== ''">
                                <div class="content-card item">
                                    <p class="font s-13 c-gray t-up a-center">FECHA DE TRAMITACIÓN</p>
                                    <span class="font s-13 c-black t-up a-center w-bold">{{ orderDetail?.order?.tramitedDate }}</span>
                                </div>
                            </div>
                            <div nz-col style="width: 100%;" *ngIf="orderDetail?.order?.changeStateDate && orderDetail?.order?.changeStateDate !== ''">
                                <div class="content-card item">
                                    <p class="font s-13 c-gray t-up a-center">Cambio de estado</p>
                                    <span class="font s-13 c-black t-up a-center w-bold">{{ orderDetail?.order?.changeStateDate }}</span>
                                </div>
                            </div>
                            <div nz-col style="width: 100%;" *ngIf="orderDetail?.order?.status && orderDetail?.order?.status !== ''">
                                <div class="content-card item">
                                    <span class="font s-13 c-gray t-up a-center">ESTADO</span>
                                    <div class="inner-content-card" style="text-align-last: center;">
                                        <app-status-order
                                            [statusName]="orderDetail?.order?.statusName" 
                                            [brand]="orderDetail?.order?.brand" 
                                            [status]="orderDetail?.order?.status" 
                                            [statusDescription]="orderDetail?.order?.statusDescription" 
                                            [title]="orderDetail?.statusDescription && orderDetail?.statusDescription !== '' ? orderDetail?.statusDescription : ''">
                                        </app-status-order>
                                    </div>
                                </div>
                            </div>
                            <div nz-col style="width: 100%;" *ngIf="orderDetail?.order?.documents?.status && orderDetail?.order?.documents?.status !== ''">
                                <div class="content-card item">
                                    <p class="font s-13 c-gray t-up a-center">DOCUMENTACIÓN</p>
                                    <div class="flex-content cursor-pointer" nz-tooltip
                                        [nzTooltipTitle]="(orderDetail?.order?.documents?.annotation && orderDetail?.order?.documents?.annotation !== '') || (orderDetail?.order?.documents?.signType && orderDetail?.order?.documents?.signType !== '') ? documentationDetail : ''">
                                        <i nz-icon nzType="file-search" nzTheme="outline"></i>
                                        <span style="margin: 0 5px" class="font s-13 c-black a-center">{{ orderDetail?.order?.documents?.status }}</span>
                                        <span class="{{ getClassDocStatus(orderDetail?.order?.documents?.status) }}"></span>
                                        <span class="font s-12"> {{ getSignTypeDocStatus(orderDetail?.order?.documents?.signType) }}</span>
                                    </div>
                                </div>
                            </div>
                            <div nz-col style="width: 100%;">
                                <div class="content-card">
                                    <img [src]="orderDetail?.order?.imageBrand"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <div class="inner-content-max">
            <div nz-row nzGutter="16">
                <div nz-col nzXXl="6" nzXl="6" nzLg="6" nzMd="6" nzXs="6" nzSm="6">
                    <ng-container  *ngIf="orderDetail?.pdv?.showPdv !== false" >
                        <div class="bar-head">
                            <span class="font s-14 w-bold c-gray t-up">DATOS PDV</span>
                        </div>
                        <div class="digo-card form-card card-gray" style="padding: 40px !important;">
                            <div class="title-content-max" *ngFor="let element of orderDetail?.pdv?.elements">
                                <p class="font s-13 c-gray t-up no-bottom-extra-large">{{ element.label }}</p>
                                <p class="font s-13 c-black">{{ element.value && element.value !== '' ? element.value : '-' }}</p>
                            </div>
                            <button nz-button
                                *ngIf="showButtonProfile(orderDetail?.pdv?.type)"
                                class="c-dark size-large"
                                (click)="goTo(orderDetail?.pdv?.type, orderDetail?.pdv?.id)"
                                nzBlock>
                                Ir a perfil {{ orderDetail?.pdv?.type && orderDetail?.pdv?.type !== '' ? orderDetail?.pdv?.type : 'pdv' }}
                            </button>
                        </div>
                    </ng-container>
                </div>
                <div nz-col nzXXl="18" nzXl="18" nzLg="18" nzMd="18" nzXs="18" nzSm="18">
                    <!-- Productos contratados -->
                    <div class="flex-content f-between bar-head">
                        <div>
                            <span class="font s-14 w-bold c-gray t-up title-head">PRODUCTOS CONTRATADOS</span>
                            <span class="tag success">Nº de Productos {{ orderDetail?.order?.products?.length }}</span>
                        </div>
                        <div *ngIf="orderDetail?.order?.updateOrder">
                            <button type="button" (click)="updateOrder()" nz-button class="c-dark custom-button-update">
                                <i nz-icon nzType="sync" nzTheme="outline"></i>Actualizar pedido
                            </button>
                        </div>
                    </div>
                    <app-list-contracted-products [products]="orderDetail?.order?.products"></app-list-contracted-products>

                    <!-- Datos portabilidad Orange -->
                    <ng-container *ngIf="orderDetail?.order?.portabilityInfo && orderDetail?.order?.portabilityInfo.length">
                        <div class="bar-head">
                            <span class="font s-14 w-bold c-gray t-up">Datos portabilidad</span>
                        </div>
                        <app-list-contracted-products [products]="orderDetail?.order?.portabilityInfo"></app-list-contracted-products>
                    </ng-container>

                    <!-- Datos de cliente -->
                    <ng-container *ngIf="orderDetail?.customer?.showCustomer !== false">
                        <div class="bar-head">
                            <span class="font s-14 w-bold c-gray t-up">Datos clientes</span>
                        </div>
                        <app-data-client-card [customer]="orderDetail?.customer" [customerDonor]="orderDetail?.customer_donor"></app-data-client-card>
                    </ng-container>

                    <!-- Observaciones -->
                    <ng-container *ngIf="orderDetail?.order?.observations">
                        <div class="bar-head">
                            <span class="font s-14 w-bold c-gray t-up">Observaciones</span>
                        </div>
                        <div class="digo-card form-card custom-space">
                            <div class="content-card">
                                <p>{{ orderDetail?.order?.observations }}</p>
                            </div>
                        </div>
                    </ng-container>

                    <!-- Teide -->
                    <ng-container *ngIf="orderDetail?.order?.teide && orderDetail?.order?.teide?.teideTransactionId">
                        <div class="bar-head">
                            <span class="font s-14 w-bold c-gray">TEIDE - Transaction ID: {{ orderDetail?.order?.teide?.teideTransactionId }} {{ orderDetail?.order?.teide?.teideTransactionIdDate && orderDetail?.order?.teide?.teideTransactionIdDate !== '' ? ('(Obtenido el ' + orderDetail?.order?.teide?.teideTransactionIdDate + ')') : '' }}</span>
                        </div>
                        <div class="digo-card form-card custom-space">
                            <div nz-row>
                                <ng-container *ngFor="let element of orderDetail?.order?.teide?.elements; let i = index">
                                    <div nz-col [nzSpan]="element.nzSpan ? element.nzSpan : '6'" *ngIf="!element.hidden && element.type !== 'arrow'">
                                        <div class="content-card">
                                            <span *ngIf="element.label && element.label !== ''" class="font s-13 c-gray t-up">{{ element.label }}</span>
                                            <p>{{ element.value && element.value !== '' ? element.value : '-' }}</p>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>

                    <!-- Payload -->
                    <ng-container *ngIf="orderDetail?.order?.payload && orderDetail?.order?.payload !== ''">
                        <div class="bar-head">
                            <span class="font s-14 w-bold c-gray">TRAZA</span>
                        </div>
                        <div class="digo-card form-card custom-space">
                            <div nz-row>                                
                                <div nz-col>
                                    <div class="content-card">
                                        <p class="wb-break-word">{{ orderDetail?.order?.payload }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!-- Payload Statuses -->
                    <ng-container *ngIf="orderDetail?.order?.payloadStatuses && orderDetail?.order?.payloadStatuses !== ''">
                        <div class="bar-head">
                            <span class="font s-14 w-bold c-gray">TRAZA DE ESTADO</span>
                        </div>
                        <div class="digo-card form-card custom-space">
                            <div nz-row>                                
                                <div nz-col>
                                    <div class="content-card">
                                        <p class="wb-break-word">{{ orderDetail?.order?.payloadStatuses }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!-- Observaciones -->
                    <ng-container *ngIf="orderDetail?.order?.annotation && orderDetail?.order?.annotation !== ''">
                        <div class="digo-card form-card custom-space">
                            <div nz-row>
                                <div nz-col class="content-card">
                                    <span class="font s-13 c-gray t-up">Observaciones</span>
                                    <p>{{ orderDetail?.order?.annotation }}</p>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #documentationDetail>
    <div *ngIf="orderDetail?.order?.documents?.annotation && orderDetail?.order?.documents?.annotation !== ''">
        Observaciones: {{ orderDetail?.order?.documents?.annotation }}
    </div>
    <div *ngIf="orderDetail?.order?.documents?.signType && orderDetail?.order?.documents?.signType !== ''">
        Tipo Subida Doc: {{ orderDetail?.order?.documents?.signType }}
    </div>
</ng-template>
