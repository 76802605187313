import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { isIterable } from "../../../core/functions";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KeyValue } from "@angular/common";
import { MustMatch } from 'src/app/core/validators/mustmatch.validators';
import { ProfilesService } from 'src/app/services/http/profiles/profiles.service';

@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit, OnChanges {

    @Input() menus: any;

    public menuEntriesCount = 0;
    public itemSelected: string = "";
    public showModal: boolean = false;
    public loadingModal: boolean = false;
    public resetPasswordFormGroup!: FormGroup;
    public resetPasswordError: string = '';
    public resetPasswordOk: boolean = false;

    constructor(
        private fb: FormBuilder,
        private profileService: ProfilesService
    ) { }

    ngOnInit(): void {
        this.resetPasswordFormGroup = this.fb.group({
            currentPassword: [null, [Validators.required]],
            newPassword: [null, [Validators.required]],
            newRepeatPassword: [null, [Validators.required]],
        }, {
            validator: MustMatch('newPassword', 'newRepeatPassword')
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.['menus']?.currentValue) {
            this.menuEntriesCount = Object.keys(this.menus).length;
        }
    }

    resetSelected(dropdownIsVisible: any) {
        if (!dropdownIsVisible) {
            this.setSelectedItem('')
        }
    }

    setSelectedItem(item: any = '') {
        this.itemSelected = item;
    }

    getPath(itemValue: any) {
        let path: any = false;
        if (isIterable(itemValue)) {
            itemValue.forEach((item:any) => {
                if (item.path) {
                    path = item.path;
                }
            })
        }
        return path;
    }

    getTooltip(itemValue: any) {
        let tooltip: any = false;
        if (isIterable(itemValue)) {
            itemValue.forEach((item:any) => {
                if (item.path) {
                    tooltip = item.tooltip;
                }
            })
        }
        return tooltip;
    }

    public changePassword(change: boolean) {
        this.showModal = change;
    }

    // para que keyvalue no ordene el objeto, que lo pille tal cual viene en getMenu.php
    originalOrder = (a: KeyValue<string,string>, b: KeyValue<string,string>): number => {
        return 0;
    }

}
