<div style="width: 100%;">
    <div class="form-group margins m-b-10">
        <input type="text" oninvalid="" required [(ngModel)]="filters.business_name" [ngClass]="{'has-value': filters.business_name}">
        <label class="control-label flex-content f-between" style="width: 100%">
            <div>
                <span class=""><b>Nombre</b></span>
            </div>
        </label><i class="bar"></i>
    </div>
    <div class="form-group margins m-b-10">
        <input type="text" oninvalid="" required [(ngModel)]="filters.pdv_id" [ngClass]="{'has-value': filters.pdv_id}">
        <label class="control-label flex-content f-between" style="width: 100%">
            <div>
                <span class=""><b>ID PDV</b></span>
            </div>
        </label><i class="bar"></i>
    </div>
    <div class="form-group margins m-b-10">
        <input type="text" oninvalid="" required [(ngModel)]="filters.company_nif" [ngClass]="{'has-value': filters.company_nif}">
        <label class="control-label flex-content f-between" style="width: 100%">
            <div>
                <span class=""><b>NIF Empresa</b></span>
            </div>
        </label><i class="bar"></i>
    </div>
    <div class="form-group margins m-b-10">
        <input type="text" oninvalid="" required [(ngModel)]="filters.address" [ngClass]="{'has-value': filters.address}">
        <label class="control-label flex-content f-between" style="width: 100%">
            <div>
                <span class=""><b>Dirección</b></span>
            </div>
        </label><i class="bar"></i>
    </div>
    <div class="form-group margins m-b-10">
        <input type="text" oninvalid="" required [(ngModel)]="filters.username" [ngClass]="{'has-value': filters.username}">
        <label class="control-label flex-content f-between" style="width: 100%">
            <div>
                <span class=""><b>Usuario</b></span>
            </div>
        </label><i class="bar"></i>
    </div>
        <div class="form-group margins m-b-10" [ngClass]="{'form-selected': showLabelSelect('distributor_id')}">
            <label for="nz-select" class="label-for-nz-select flex-content f-between left-inherit" style="width: 100%" *ngIf="showLabelSelect('distributor_id')">
                <b>Distribuidor</b>
            </label>
        <nz-select nzShowSearch nzAllowClear (nzBlur)="focusSelect('distributor_id',false)" (nzFocus)="focusSelect('distributor_id',true)" nzPlaceHolder="Distribuidor" [(ngModel)]="filters.distributor_id" nzBorderless style="width: 100%;" (ngModelChange)="changeFilterDistributor($event)">
            <nz-option [nzValue]="item.id" [nzLabel]="item.name" *ngFor="let item of masters.distributors"></nz-option>
        </nz-select>
        <i class="bar"></i>
    </div>
        <div class="form-group margins m-b-10" [ngClass]="{'form-selected': showLabelSelect('merchant_id')}">
            <label for="nz-select" class="label-for-nz-select flex-content f-between left-inherit" style="width: 100%" *ngIf="showLabelSelect('merchant_id')">
                <b>GPV</b>
            </label>
        <nz-select nzShowSearch nzAllowClear (nzBlur)="focusSelect('merchant_id',false)" (nzFocus)="focusSelect('merchant_id',true)" nzPlaceHolder="GPV" [(ngModel)]="filters.merchant_id" nzBorderless style="width: 100%;">
            <nz-option [nzValue]="item.id" [nzLabel]="item.name" *ngFor="let item of masters.merchan"></nz-option>
        </nz-select>
        <i class="bar"></i>
    </div>
        <div class="form-group margins m-b-10" [ngClass]="{'form-selected': showLabelSelect('activated')}">
            <label for="nz-select" class="label-for-nz-select flex-content f-between left-inherit" style="width: 100%" *ngIf="showLabelSelect('activated')">
                <b>Estado</b>
            </label>
        <nz-select nzShowSearch nzAllowClear (nzBlur)="focusSelect('activated',false)" (nzFocus)="focusSelect('activated',true)" nzPlaceHolder="Estado" [(ngModel)]="filters.activated" nzBorderless style="width: 100%;">
            <nz-option [nzValue]="item.value" [nzLabel]="item.name" *ngFor="let item of masters.statuses"></nz-option>
        </nz-select>
        <i class="bar"></i>
    </div>
    <div *ngIf="isCollapsed && masters">
        <div class="form-group margins m-b-10">
            <input type="text" oninvalid="" required [(ngModel)]="filters.company_id" [ngClass]="{'has-value': filters.company_id}">
            <label class="control-label flex-content f-between" style="width: 100%">
                <div>
                    <span class=""><b>ID Empresa</b></span>
                </div>
            </label><i class="bar"></i>
        </div>
        <div class="form-group margins m-b-10">
            <input type="text" oninvalid="" required [(ngModel)]="filters.contact_phone" [ngClass]="{'has-value': filters.contact_phone}">
            <label class="control-label flex-content f-between" style="width: 100%">
                <div>
                    <span class=""><b>Teléfono</b></span>
                </div>
            </label><i class="bar"></i>
        </div>
        <div class="form-group margins m-b-10">
            <input type="text" oninvalid="" required [(ngModel)]="filters.contact_email" [ngClass]="{'has-value': filters.contact_email}">
            <label class="control-label flex-content f-between" style="width: 100%">
                <div>
                    <span class=""><b>E-mail</b></span>
                </div>
            </label><i class="bar"></i>
        </div>
        <div class="form-group margins m-b-10">
            <div nz-row [nzGutter]="0">
                <div nz-col [nzXs]="24" [nzSm]="24" [nzLg]="12" [nzMd]="12" [nzLg]="24" [nzXl]="24" [nzXXl]="24" style="margin-bottom: 10px;">
                    <input type="date" oninvalid="" [(ngModel)]="filters.date_from">
                    <label class="control-label flex-content f-between" style="width: 100%">
                        <div>
                            <span class=""><b>Desde</b></span>
                        </div>
                    </label><i class="bar"></i>
                </div>
                <div nz-col [nzXs]="24" [nzSm]="24" [nzLg]="12" [nzMd]="12" [nzLg]="24" [nzXl]="24" [nzXXl]="24">
                    <input type="date" oninvalid="" [(ngModel)]="filters.date_until">
                    <label class="control-label flex-content f-between" style="width: 100%">
                        <div>
                            <span class=""><b>Hasta</b></span>
                        </div>
                    </label><i class="bar"></i>
                </div>
            </div>
        </div>
        <!--<div class="form-group margins m-b-10" [ngClass]="{'form-selected': showLabelSelect('num_act')}">
            <label for="nz-select" class="label-for-nz-select flex-content f-between left-inherit" style="width: 100%" *ngIf="showLabelSelect('num_act')">
                <b>Nº de activaciones este mes</b>
            </label>
            <nz-select nzPlaceHolder="Nº de activaciones este mes" [(ngModel)]="filters.num_act" nzBorderless style="width: 100%;">
                <nz-option [nzValue]="item.value" [nzLabel]="item.name" *ngFor="let item of masters.activation"></nz-option>
            </nz-select>
            <i class="bar"></i>
        </div>-->
        <div class="form-group margins m-b-10" [ngClass]="{'form-selected': showLabelSelect('delegation_id')}">
            <label for="nz-select" class="label-for-nz-select flex-content f-between left-inherit" style="width: 100%" *ngIf="showLabelSelect('delegation_id')">
                <b>Delegación</b>
            </label>
            <nz-select nzShowSearch nzAllowClear (nzBlur)="focusSelect('delegation_id',false)" (nzFocus)="focusSelect('delegation_id',true)" nzPlaceHolder="Delegación" [(ngModel)]="filters.delegation_id" nzBorderless style="width: 100%;">
                <nz-option [nzValue]="item.id" [nzLabel]="getDenominationName(item)" *ngFor="let item of masters.delegation"></nz-option>
            </nz-select>
            <i class="bar"></i>
        </div>
        <div class="form-group margins m-b-10" [ngClass]="{'form-selected': showLabelSelect('type_channel_id')}">
            <label for="nz-select" class="label-for-nz-select flex-content f-between left-inherit" style="width: 100%" *ngIf="showLabelSelect('type_channel_id')">
                <b>Tipo de Canal</b>
            </label>
            <nz-select nzShowSearch nzAllowClear (nzBlur)="focusSelect('type_channel_id',false)" (nzFocus)="focusSelect('type_channel_id',true)" nzPlaceHolder="Tipo de Canal" [(ngModel)]="filters.type_channel_id" nzBorderless style="width: 100%;">
                <nz-option [nzValue]="item.id" [nzLabel]="item.name" *ngFor="let item of masters.typeChannel"></nz-option>
            </nz-select>
            <i class="bar"></i>
        </div>
        <div class="form-group margins m-b-10" [ngClass]="{'form-selected': showLabelSelect('clasification_id')}">
            <label for="nz-select" class="label-for-nz-select flex-content f-between left-inherit" style="width: 100%" *ngIf="showLabelSelect('clasification_id')">
                <b>Segmentación</b>
            </label>
            <nz-select nzShowSearch nzAllowClear (nzBlur)="focusSelect('clasification_id',false)" (nzFocus)="focusSelect('clasification_id',true)" nzPlaceHolder="Segmentación" [(ngModel)]="filters.clasification_id" nzBorderless style="width: 100%;">
                <nz-option [nzValue]="item.id" [nzLabel]="item.name" *ngFor="let item of masters.clasifications"></nz-option>
            </nz-select>
            <i class="bar"></i>
        </div>
        <div class="form-group margins m-b-10">
            <input type="text" oninvalid="" required [(ngModel)]="filters.dealer_code" [ngClass]="{'has-value': filters.dealer_code}">
            <label class="control-label flex-content f-between" style="width: 100%">
                <div>
                    <span class=""><b>SFID MásMovil</b></span>
                </div>
            </label><i class="bar"></i>
        </div>
    </div>
    <div class="margins m-b-30">
        <a class="font s-16 c-primary" (click)="onShow()">{{ collapsedText }}</a>
    </div>
    <div class="form-group form-group-footer margins m-t-30">
        <div class="flex-content a-center">
            <button nz-button class="c-dark size-large" nzBlock (click)="onSubmit()">Buscar</button>
        </div>
    </div>
</div>
