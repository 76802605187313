import { Component, HostListener, OnInit } from '@angular/core';
import { DigoService } from './services/digo.service';
import { AuthService } from "./services/auth.service";
import { allLocalStorage, pageLimit } from "./shared/constantes";
import { environment } from "../environments/environment";
import { MenuService } from "./services/menu.service";
import { filter, pairwise } from "rxjs";
import { UserService } from "./services/http/user/user.service";
import { NavigationEnd, Router, RoutesRecognized } from "@angular/router";
import { JazztelService } from './services/http/jazztel/jazztel.service';
import { SimyoService } from './services/http/simyo/simyo.service';
import { OrangeService } from './services/http/orange/orange.service';
import { PdvService } from './services/http/pdv/pdv.service';
import { CompanyService } from './services/http/company/company.service';
import { LogisticsService } from './services/http/herramientas/logistics.service';
import { detectIncognito } from 'detect-incognito';
import { Meta } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { DinamizacionService } from './services/http/herramientas/dinamizacion.service';
import { Location } from '@angular/common';
import { MasMovilService } from './services/http/masmovil/masmovil.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    public title = 'digo-admin';
    public menus: any;
    public error = '';
    public loading = true;
    public showModalCloseSession: boolean = false;
    private requestUser: any;

    constructor(
        private digoService: DigoService,
        public auth: AuthService,
        private userService: UserService,
        private menuService: MenuService,
        private router: Router,
        private jazztelService: JazztelService,
        private simyoService: SimyoService,
        private orangeService: OrangeService,
        private pdvService: PdvService,
        private companyService: CompanyService,
        private logisticsService: LogisticsService,
        private meta: Meta,
        private dinamizacionService: DinamizacionService,
        private location: Location,
        private masmovilService: MasMovilService
    ) { }

    ngOnInit(): void {
        detectIncognito().then((result) => {
            this.auth.setIncognitoMode(result.isPrivate);
        });

        this.router.events.subscribe((val:any) => {
            if (val.routerEvent instanceof NavigationEnd) {
                if (val.routerEvent.url.includes('/login') || val.routerEvent.url.includes('/jwt')) {
                    this.loading = false;
                }
            }
        })

        this.router.events
            .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
            .subscribe((events: RoutesRecognized[]) => {
                // Jazztel
                if (events[1].urlAfterRedirects === '/jazztel/listado-activaciones' && !events[0].urlAfterRedirects.includes('/jazztel/listado-activaciones')) {
                    this.jazztelService.setFilters(null);
                    this.jazztelService.setPaginator({limit: pageLimit, totalPages: 0, currentPage: 1, totalItems: 0});
                    this.jazztelService.setOrderBy(null);
                }
                // Simyo
                if (events[1].urlAfterRedirects === '/simyo/listado-activaciones' && !events[0].urlAfterRedirects.includes('/simyo/listado-activaciones')) {
                    this.simyoService.setFilters(null);
                    this.simyoService.setPaginator({limit: pageLimit, totalPages: 0, currentPage: 1, totalItems: 0});
                    this.simyoService.setOrderBy(null);
                }
                // Simyo duplicados
                if (events[1].urlAfterRedirects === '/simyo/listado-duplicados' && !events[0].urlAfterRedirects.includes('/simyo/listado-duplicados')) {
                    this.simyoService.setDuplicadoFilters(null);
                    this.simyoService.setDuplicadoPaginator({limit: pageLimit, totalPages: 0, currentPage: 1, totalItems: 0});
                }
                // Simyo Autoactivaciones
                if (events[1].urlAfterRedirects === '/simyo/listado-autoactivaciones' && !events[0].urlAfterRedirects.includes('/simyo/listado-autoactivaciones')) {
                    this.simyoService.setFilters(null);
                    this.simyoService.setPaginator({limit: pageLimit, totalPages: 0, currentPage: 1, totalItems: 0});
                    this.simyoService.setOrderBy(null);
                }
                // Orange
                if (events[1].urlAfterRedirects === '/orange/prepago-listado-activaciones' && !events[0].urlAfterRedirects.includes('/orange/prepago-listado-activaciones')) {
                    this.orangeService.setFilters(null);
                    this.orangeService.setPaginator({limit: pageLimit, totalPages: 0, currentPage: 1, totalItems: 0});
                    this.orangeService.setOrderBy(null);
                }
                // Perfiles
                if (events[1].urlAfterRedirects === '/perfiles' && (!events[0].urlAfterRedirects.includes('/perfiles/pdv') && !events[0].urlAfterRedirects.includes('/perfiles/empresa'))) {
                    this.pdvService.setFilters({activated: 'true'});
                    this.pdvService.setPage(1);
                    this.companyService.setFilters({activated: 'true'});
                    this.companyService.setPage(1);
                }
                // Pedidos
                if (events[1].urlAfterRedirects === '/herramientas/logistica/pedidos' && !events[0].urlAfterRedirects.includes('/herramientas/logistica/pedidos')) {
                    this.logisticsService.setListadoPedidosFilter(null);
                    this.logisticsService.setListadoPedidosOrderBy(null);
                    this.logisticsService.setListadoPedidosPaginator({limit: pageLimit, totalPages: 0, currentPage: 1, totalItems: 0});
                }
                // Listado grupos
                if (events[1].urlAfterRedirects === '/herramientas/logistica/grupos' && !events[0].urlAfterRedirects.includes('/herramientas/logistica/grupos')) {
                    this.logisticsService.setListadoGruposFilter(null);
                    this.logisticsService.setListadoGruposOrderBy(null);
                    this.logisticsService.setListadoGruposPaginator({limit: pageLimit, totalPages: 0, currentPage: 1, totalItems: 0});
                }
                // Listado artículos
                if (events[1].urlAfterRedirects === '/herramientas/logistica/articulos' && !events[0].urlAfterRedirects.includes('/herramientas/Herramientas-Detalle')) {
                    this.logisticsService.setListadoArticulosFilter(null);
                    this.logisticsService.setListadoArticulosPaginator({limit: 15, totalPages: 0, currentPage: 1, totalItems: 0});
                    this.logisticsService.setListadoArticulosOrderBy(null);
                }
                // Masmovil, Guuk, Pepephone
                if ((events[1].urlAfterRedirects === '/masmovil/listado-activaciones' && !events[0].urlAfterRedirects.includes('/masmovil/listado-activaciones'))
                || (events[1].urlAfterRedirects === '/guuk/listado-activaciones' && !events[0].urlAfterRedirects.includes('/guuk/listado-activaciones'))
                || (events[1].urlAfterRedirects === '/pepephone/listado-activaciones' && !events[0].urlAfterRedirects.includes('/pepephone/listado-activaciones'))) {
                    this.masmovilService.setFilters(null);
                    this.masmovilService.setPaginator({limit: pageLimit, totalPages: 0, currentPage: 1, totalItems: 0});
                    this.masmovilService.setOrderBy(null);
                }

                if (events[1].urlAfterRedirects === '/herramientas/dinamizacion/incentivos'
                && (!events[0].urlAfterRedirects.includes('/herramientas/dinamizacion/incentivos/editar') && !events[0].urlAfterRedirects.includes('/herramientas/dinamizacion/incentivos/crear'))) {
                    this.dinamizacionService.setFilters(null);
                    this.dinamizacionService.setPage(1);
                }
            });

        this.auth.getTokenSet()
            .subscribe((tkn: any) => {
                console.log('token' + tkn);
                if (tkn && tkn !== '') {
                    console.log('PIDO USUARIO');
                    this.userService.userInfo();
                }
            })

        /*if (!this.auth.checkIfTokenHasExpired()) {
            this.loadUserInfo()
        } else {
            console.log('Creo un timer para comprobar el token');
            setTimeout(() => {
                this.auth.check();
            }, 5000);
        }*/

        setInterval(() => {
            this.digoService.keepAlive()
            .subscribe({
                next: (resp:any) => {
                    if (!resp || resp !== 200) {
                        this.digoService.goLogin();
                    }
                },
                error: (error: HttpErrorResponse) => {
                        this.digoService.goLogin();
                },
                complete:() => {
                }
            })
        }, 600000); //10 minutos, 600000 milisegundos.

        this.menuService.getMenu().subscribe(menu => {
            this.menus = menu
        });
        this.auth.getUserInfoSuscription().subscribe(user => this.loading = false);
        this.viewport();
    }

    private loadUserInfo() {
        /*if (this.requestUser) this.requestUser.unsubscribe();
        this.requestUser = this.userService.userInfo()
            .subscribe({
                next: value => {
                    this.loading = false;
                },
                error: err => {
                    this.error = 'Ha ocurrido un error cargando el frontal';
                    this.loading = false;
                }
            })*/
    }


    @HostListener('window:message', ['$event'])
    onMessage(event: any) {
        let data = event.data;
        if (typeof data === 'string') {
            if (data.startsWith('RedirectTo|')) {
                window.location.href = data.replace('RedirectTo|', '');
            }
            if (data.startsWith('sessionExpired')) {
                this.auth.setResetIframeState(true);
                this.showModalCloseSession = true;
            }
            if (data.startsWith('iframe-close')) {
                let url = data.replace('iframe-close|', '');
                const previousUrl = this.location.path();
                if (previousUrl.includes(url)) {
                    this.location.back();
                } else {
                this.router.navigate([url], { replaceUrl: true });
                }
            }
        }
    }

    public viewport(): void {

        if (localStorage.getItem(allLocalStorage.view) !== 'desktop') {
            this.meta.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' })
        }
      }

}
