import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {JwtComponent} from "./core/jwt/jwt.component";
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {path: 'jwt', component: JwtComponent, data: {title:'Seguridad'}},
      { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule), data: {title:'Login'} },
      { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard], data: {title:'Página de Inicio'}  },
      { path: 'herramientas', loadChildren: () => import('./pages/herramientas/herramientas.module').then(m => m.HerramientasModule), canActivate: [AuthGuard], data: {title:'Herramientas'} },
      { path: 'clients', loadChildren: () => import('./pages/clients/clients.module').then(m => m.ClientsModule), canActivate: [AuthGuard], data: {title:'Clientes'} },
      { path: 'activation-request', loadChildren: () => import('./pages/activation-request/activation-request.module').then(m => m.ActivationRequestModule), canActivate: [AuthGuard], data: {title:'Solicitudes de activación'}},
      { path: 'orange', loadChildren: () => import('./pages/orange/orange.module').then(m => m.OrangeModule), canActivate: [AuthGuard] , data: {title:'Orange'}},
      { path: 'simyo', loadChildren: () => import('./pages/simyo/simyo.module').then(m => m.SimyoModule), canActivate: [AuthGuard] , data: {title:'Simyo'}},
      { path: 'jazztel', loadChildren: () => import('./pages/jazztel/jazztel.module').then(m => m.JazztelModule), canActivate: [AuthGuard], data: {title:'Jazztel'} },
      { path: 'masmovil', loadChildren: () => import('./pages/masmovil/masmovil.module').then(m => m.MasMovilModule), canActivate: [AuthGuard] , data: {title:'Masmovil'}},
      { path: 'guuk', loadChildren: () => import('./pages/guuk/guuk.module').then(m => m.GuukModule), canActivate: [AuthGuard] , data: {title:'Guuk'}},
      { path: 'pepephone', loadChildren: () => import('./pages/pepephone/pepephone.module').then(m => m.PepephoneModule), canActivate: [AuthGuard] , data: {title:'Pepephone'}},
      { path: 'test/list', loadChildren: () => import('./test-list/test-list.module').then(m => m.TestListModule), canActivate: [AuthGuard], data: {title:'Test'} },
      { path: 'pdv-permisos', loadChildren: () => import('./pages/pdv-permisos/pdv-permisos.module').then(m => m.PdvPermisosModule), canActivate: [AuthGuard], data: {title:'Permisos PdV'} },
      { path: 'pdv-form-newpdv', loadChildren: () => import('./pages/pdv-form-newpdv/pdv-form-newpdv.module').then(m => m.PdvFormNewpdvModule ), canActivate: [AuthGuard], data: {title:'PdV Formulario'} },
      { path: 'permissions-tools', loadChildren: () => import('./pages/permissions-tools/permissions-tools.module').then(m => m.PermissionsToolsModule), canActivate: [AuthGuard], data: {title:'Permisos'} },
      { path: 'perfiles', loadChildren: () => import('./pages/profiles/profiles.module').then(m => m.ProfilesModule), canActivate: [AuthGuard], data: {title:'Perfiles'}  },
      { path: 'tools-list', loadChildren: () => import('./pages/tools-list/tools-list.module').then(m => m.ToolsListModule), canActivate: [AuthGuard], data: {title:'Listado de herramientas'} },
      { path: 'digo-app', loadChildren: () => import('./pages/digo-app/digo-app.module').then(m => m.DigoAppModule), canActivate: [AuthGuard] , data: {title:'DigoApp'}},
      { path: '**', redirectTo: '' },
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
